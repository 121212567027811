import {
  Auth0Client,
  Auth0ClientOptions,
  LogoutOptions,
  RedirectLoginOptions,
  RedirectLoginResult
} from '@auth0/auth0-spa-js';
import { Logger } from 'loglevel';
import { navigateToUrl } from 'single-spa';

const MANUAL_LOGIN_KEY = 'manual_login';

function getUrlWithoutOrigin(url: string | URL): string {
  url = new URL(url);
  return url.href.replace(url.origin, '');
}

function isManualLogin(): boolean {
  return new URL(location.href).searchParams.get(MANUAL_LOGIN_KEY)?.toLowerCase() === 'true'
}

export class SWOAuth0Client extends Auth0Client {
  private _redirectLoginResult: Promise<RedirectLoginResult>;

  constructor(options: Auth0ClientOptions,
    private logger: Logger) {
    super({
      ...options,
      audience: __AUTH0_AUDIENCE__ || ''
    });
  }

  loginWithRedirect(options?: RedirectLoginOptions): Promise<void> {
    const manualLogin = isManualLogin();
    const url = new URL(location.href);
    let connection = __AUTH0_CONNECTION__ || '';

    if (manualLogin) {
      url.searchParams.delete(MANUAL_LOGIN_KEY);
      connection = '';
    }

    const encodedRedirectUri = encodeURIComponent(getUrlWithoutOrigin(url));
    const appState = { ...options?.appState, encodedRedirectUri };

    this.logger.debug(`Logging in with redirect.`, appState);

    const overrides: RedirectLoginOptions = {
      appState,
      connection
    };
    return super.loginWithRedirect({
      ...options,
      ...overrides
    });
  }

  logout(options?: LogoutOptions): Promise<void> | void {
    const returnTo = options?.returnTo || location.origin;
    const newOptions = { ...options, returnTo };

    this.logger.debug(`Logging out and redirecting to: ${returnTo}.`);

    return super.logout(newOptions);
  }

  handleRedirectCallback(url?: string): Promise<RedirectLoginResult> {
    return this._redirectLoginResult || super.handleRedirectCallback(url);
  }

  async tryRedirectAfterLogin(): Promise<void> {
    try {
      this._redirectLoginResult = this.handleRedirectCallback();
      const result = await this._redirectLoginResult;

      const { encodedRedirectUri } = result.appState;

      if (!encodedRedirectUri)
        return;

      const redirectTo = decodeURIComponent(encodedRedirectUri);

      if (getUrlWithoutOrigin(location.href) === redirectTo)
        return;

      this.logger.debug(`Redirecting to ${redirectTo} after sign in.`);
      navigateToUrl(redirectTo);
    } catch {
    }
  }
}
