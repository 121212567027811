import { Auth0Client } from '@auth0/auth0-spa-js';
import { SWOAuth0Client } from './client';
import { LoggerFactory } from '../logger';

export { createAutoLogin } from './auto-login'

export async function createAuth0Client(getLogger: LoggerFactory): Promise<Auth0Client> {
  const logger = getLogger('Auth0');

  logger.info(`Creating client...`);

  // TEMPORARY AVAILIBILITY TO OVERRIDE AUTH0 SETTINGS
  const domain = window.localStorage.getItem('AUTH0_DOMAIN') || __AUTH0_DOMAIN__;
  const client_id = window.localStorage.getItem('AUTH0_CLIENT_ID') || __AUTH0_CLIENT_ID__;

  const auth0Client = new SWOAuth0Client({
    domain,
    client_id,
    redirect_uri: location.origin
  }, logger);

  await auth0Client.checkSession();
  await auth0Client.tryRedirectAfterLogin();

  logger.info(`Client ready.`);

  return auth0Client;
}
