import { getMountedApps } from 'single-spa';

export function onceUnmounted(appName: string, action: () => void) {
  const listener = () => {
    const mountedApps = getMountedApps();

    if (!mountedApps.includes(appName)) {
      window.removeEventListener("single-spa:app-change", listener);
      action();
    }
  };

  window.addEventListener("single-spa:app-change", listener);
}
