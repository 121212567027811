import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { MessageBus } from '@swo/message-bus';
import { Logger } from 'loglevel';
import { onceUnmounted } from './once-unmounted';

export function createMessageBus(appName: string, subjects: Map<string, Subject<unknown>>, logger: Logger): MessageBus {
  const subscriptions = new Set<Subscription>();

  const createIfNotExist = (name: string) => {
    if (!subjects.has(name)) {
      logger.info(`${name} bus does not exist. Creating.`)
      subjects.set(name, new ReplaySubject<unknown>(1));
    }
  }

  onceUnmounted(appName, () => {
    if (!subscriptions.size)
      return;

    logger.info(`App ${appName} unmounted. ${subscriptions.size} subscriptions left. Cleaning up...`);
    subscriptions.forEach(subscription => subscription.unsubscribe());
    logger.info(`Clean up finished.`);
  });

  return {
    subscribe(name, callback) {
      createIfNotExist(name);

      logger.info(`App ${appName} subscribes to ${name} bus.`);

      const sub = subjects.get(name).subscribe(value => {
        logger.debug(`App ${appName} receives on ${name} bus:`, value);
        callback(value);
      });

      subscriptions.add(sub);
      sub.add(() => {
        logger.info(`App ${appName} unsubscribes from ${name} bus.`)
        subscriptions.delete(sub);
      });

      return () => sub.unsubscribe();
    },
    send(name, value) {
      createIfNotExist(name);

      logger.debug(`App ${appName} writes to ${name} bus:`, value);
      subjects.get(name).next(Object.freeze(value))
    }
  };
}
