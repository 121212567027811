import { ErrorNavigator } from '@swo/error-navigator';
import { Logger } from 'loglevel';
import { LoggerFactory } from './logger';

const createNavigationFn = (url: string, logger: Logger) => () => {
  const currentUrl = window.location.href;
  setTimeout(() => {
    logger.info('Navigating to error page: ', url.replace('/', ''), ', current URL: ', currentUrl);
    window.history.replaceState({ url: currentUrl }, null, url);
  });
}

export function setupErrorNavigator(getLogger: LoggerFactory): ErrorNavigator {
  const logger = getLogger('Error Navigation');
  return {
    navigateTo403: createNavigationFn('/403', logger),
    navigateTo404: createNavigationFn('/404', logger),
    navigateTo500: createNavigationFn('/500', logger)
  }
}
