import { AppsData } from './app-data';
import { getRoutes } from './routes';

const getAppDataWithDefaults = (appsData: AppsData) =>
  Object.keys(appsData).reduce((previousValue, currentValue) => {
    if (__DEFAULT_WRAPPER_CLASS__) {
      appsData[currentValue].wrapperClass ??= __DEFAULT_WRAPPER_CLASS__;
    }
    return { ...previousValue, [currentValue]: appsData[currentValue] };
  }, {});

function getDynamicRoutesTemplate(appsData: AppsData): string {
  const routes = getRoutes(appsData);

  return routes
    .map(
      route => `<route path="${route.path}">${route.apps.map(app => generateWrappedApplicationTag(appsData, app.name)).join('')}</route>`
    )
    .join('');
}

function generateWrappedApplicationTag(appsData: AppsData, appName: string): string {
  const application = `<application name="${appName}"></application>`;
  if (!appsData?.[appName]?.wrapperClass) {
    return application;
  }
  return `<div class="${appsData[appName].wrapperClass}">${application}</div>`;
}

function getFilteredAppsData(appsData: AppsData, isFullScreen: boolean) {
  const filteredAppsData: AppsData = {};
  Object.keys(appsData).forEach(key => {
    if ((isFullScreen && appsData[key].isFullScreen) || (!isFullScreen && !appsData[key].isFullScreen)) {
      filteredAppsData[key] = appsData[key];
    }
  });
  return filteredAppsData;
}

export function getDynamicTemplate(appsData: AppsData): string {
  const appsDataWithDefaults = getAppDataWithDefaults(appsData);
  const fullScreenAppsData = getFilteredAppsData(appsDataWithDefaults, true);
  const nonFullScreenAppsData = getFilteredAppsData(appsDataWithDefaults, false);
  const fullScreenAppsDynamicRoutesTemplate = getDynamicRoutesTemplate(fullScreenAppsData);
  const nonFullScreenAppsDynamicRoutesTemplate = getDynamicRoutesTemplate(nonFullScreenAppsData);
  const applicationTagReg = /<application[^>]*?name=(["\'])?((?:.(?!\1|>))*.?)\1?[^>]*><\/application>/gm;
  return require(`./layout.html`)
    .replace(applicationTagReg, (...args) => generateWrappedApplicationTag(appsDataWithDefaults, args[2]))
    .replace('<!-- FULL SCREEN APPS DYNAMIC ROUTES -->', fullScreenAppsDynamicRoutesTemplate)
    .replace('<!-- NON FULL SCREEN APPS DYNAMIC ROUTES -->', nonFullScreenAppsDynamicRoutesTemplate);
}
