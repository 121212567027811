import { Logger } from 'loglevel';
import { onceUnmounted } from './once-unmounted';
import type { TitleSetter } from '@swo/title'

const MAX_LENGTH = 30;

export type TitleManager = {
  clearTitle: (appName: string) => void,
  setTitle: (appName: string, level: number, value: string) => () => void
};

export type TitleFragments = Map<string, Map<number, string>>;

export const createTitleManager = (logger: Logger) => {
  const fragments = new Map<string, Map<number, string>>();

  const buildTitle = () => {
    const orderedFragments: string[] = [];

    fragments.forEach(appFragments => {
      appFragments.forEach((value, level) => {
        orderedFragments[level] = value;
      });
    })

    let title = "";

    for (let i = orderedFragments.length - 1; i >= 0; i--) {
      let fragment = orderedFragments[i];
      if (!fragment)
        continue;

      if (fragment.length > MAX_LENGTH)
        fragment = `${fragment.slice(0, Math.ceil(MAX_LENGTH / 2))}…${fragment.slice(fragment.length - Math.floor(MAX_LENGTH / 2))}`;

      if (title.length)
        title += ' | ';

      title += fragment;
    }

    document.title = title;
  }

  return {
    clearTitle(appName) {
      logger.info(`Removing segments for ${appName}`);
      fragments.delete(appName);
      buildTitle();
    },
    setTitle(appName, level, value) {
      if (!fragments.has(appName)) {
        logger.info(`Starting for ${appName}`);
        fragments.set(appName, new Map());
      }

      logger.info(`${appName} adds ${level}-level title segment: ${value}`);

      fragments.get(appName).set(level, value);
      buildTitle();

      return () => {
        fragments.get(appName).delete(level);
        buildTitle();
      }
    },
  } as TitleManager;
}

export const createTitleSetter = (appName: string, titleService: TitleManager): TitleSetter => {
  onceUnmounted(appName, () => titleService.clearTitle(appName));

  return (level, value) => titleService.setTitle(appName, level, value);
}
