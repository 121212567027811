import { filter, ReplaySubject } from 'rxjs';
import type { i18n } from 'i18next';
import { onceUnmounted } from '../once-unmounted';

const language = new ReplaySubject<string>();

export type I18nSync = (appName: string) => unknown

export default ((appName: string) => {
  return {
    type: '3rdParty',
    init: (i18n: i18n) => {
      const sub = language
        .pipe(filter(l => l !== i18n.language))
        .subscribe(l => i18n.changeLanguage(l));

      const notifier = (lang: string) => language.next(lang);
      i18n.on('languageChanging', notifier);

      onceUnmounted(appName, () => {
        i18n.off('languageChanging', notifier);
        sub.unsubscribe();
      });
    }
  }
}) as I18nSync
