import { LOAD_ERROR, addErrorHandler, getAppStatus, navigateToUrl, start } from 'single-spa';
import { constructApplications, constructLayoutEngine, constructRoutes } from 'single-spa-layout';
import { getAppsData } from './app-data';
import { createAutoLogin } from './auth';
import { getDynamicTemplate } from './dynamic-template';
import { setupGA } from './ga';
import { createLoggerFactory } from './logger';
import setupServiceWorker, { unregister } from './service-worker';
import { getShellProps } from './shell-props';
import { createSWOAppRegistrator } from './swo-app';

import './main.scss';

(async () => {
  const getLogger = createLoggerFactory(__LOG_LEVEL__);
  setupServiceWorker(__SERVICE_WORKER__, getLogger);
  setupGA(__GA_ID__);

  const [appsData, shellProps] = await Promise.all([getAppsData(), getShellProps(getLogger)]);
  const registerSWOApp = createSWOAppRegistrator(shellProps);

  const layoutTemplate = getDynamicTemplate(appsData);

  const routes = constructRoutes(layoutTemplate);

  const autoLogin = createAutoLogin(appsData, shellProps.auth0Client, getLogger);

  const applications = constructApplications({
    routes,
    loadApp: async ({ name }) => {
      await autoLogin(name);
      return System.import(name);
    },
  });

  const layoutEngine = constructLayoutEngine({ routes, applications });

  applications.forEach(registerSWOApp);
  layoutEngine.activate();
  start();

  addErrorHandler(err => {
    if (getAppStatus(err.appOrParcelName) !== LOAD_ERROR) return;

    const key = `swo-retries-count-${err.appOrParcelName}`;
    const retriesCount = parseInt(localStorage.getItem(key) || '0');

    //avoiding infinite loop
    if (retriesCount > 0) {
      localStorage.removeItem(key);
      navigateToUrl('/404');
      return;
    }

    localStorage.setItem(key, (retriesCount + 1).toString());

    unregister();
    location.reload();
  });

  window.addEventListener('single-spa:app-change', () => {
    document.getElementById('app-loader')?.remove();
  });
  
})();
