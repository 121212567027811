import { Auth0Client } from '@auth0/auth0-spa-js';
import { GetRolesFn, GetUserDataFn, setupRoles, setupUserData } from '@swo/user-web';
import { createAuth0Client } from './auth';
import { ErrorNavigator } from '@swo/error-navigator';
import { setupErrorNavigator } from './error-navigation';
import { LoggerFactory } from './logger';
import i18nSync, { I18nSync } from './i18next/sync';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { setupAppInsights } from './app-insights';

export interface ShellProps {
  auth0Client: Auth0Client;
  appInsights: ReactPlugin;
  getUserData: GetUserDataFn;
  getRoles: GetRolesFn;
  getLogger: LoggerFactory;
  errorNavigator: ErrorNavigator;
  i18nSync: I18nSync;
}

export async function getShellProps(getLogger: LoggerFactory): Promise<ShellProps> {
  const auth0Client = await createAuth0Client(getLogger) as any;
  const getUserData = setupUserData(auth0Client, __TENANTS_URL__);
  const getRoles = setupRoles(auth0Client, __ROLES_URL__);
  const appInsights = setupAppInsights(getUserData);
  const errorNavigator = setupErrorNavigator(getLogger);

  return {
    auth0Client,
    appInsights,
    getUserData,
    getRoles,
    getLogger,
    errorNavigator,
    i18nSync
  };
}
