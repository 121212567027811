import { Auth0Client } from '@auth0/auth0-spa-js';

import { AppsData } from '../app-data';
import { LoggerFactory } from '../logger';

export type AutoLogin = (appName: string) => Promise<unknown>;

export function createAutoLogin(appsData: AppsData, auth0Client: Auth0Client, getLogger: LoggerFactory): AutoLogin {
  const autoLoginApps = Object.keys(appsData).reduce((all, name) => (appsData[name].requiresLogin ? [...all, name] : all), [] as string[]);
  const logger = getLogger('Autologin');

  const login = async () => {
    let isAuthenticated = false;

    try {
      isAuthenticated = !!(await auth0Client.getTokenSilently());
    } catch { }

    if (isAuthenticated) {
      logger.info(`Authenticated.`);
      return;
    }

    logger.info(`Not authenticated. Redirecting...`);
    await auth0Client.loginWithRedirect();
    await new Promise(() => { }); // prevents page from rendering
  };

  let loginResult: Promise<void>

  return appName => {
    if (autoLoginApps.includes(appName)) {
      logger.info(`Auto login required by ${appName}`);

      loginResult = loginResult || login();
      return loginResult;
    }

    logger.debug(`Auto login not required by ${appName}`);
    return Promise.resolve();
  };
}
