import { registerApplication, RegisterApplicationConfig } from "single-spa";
import { createMessageBus } from "./mesage-bus";
import { Subject } from "rxjs";
import { ShellProps } from "./shell-props";
import { createTitleManager, createTitleSetter } from "./title";

export type RegisterSWOAppFn = (config: RegisterApplicationConfig) => void;

export function createSWOAppRegistrator(shellProps: ShellProps): RegisterSWOAppFn {

  const subjects = new Map<string, Subject<unknown>>();

  const t = createTitleManager(shellProps.getLogger('Page Title'));

  return ({ name, customProps, activeWhen, app }: RegisterApplicationConfig<unknown>) => {
    const templateProps = (customProps instanceof Function) ? customProps(name, location) : customProps;

    const appProps = {
      ...templateProps,
      ...shellProps,
      messageBus: createMessageBus(name, subjects, shellProps.getLogger('Message Bus')),
      titleSetter: createTitleSetter(name, t)
    };

    registerApplication({
      name,
      activeWhen,
      app,
      customProps:
        appProps
    });
  };
}
