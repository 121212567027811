import { generateW3CId } from '@microsoft/applicationinsights-core-js';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, IApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';

import { GetUserDataFn, UserData } from '@swo/user-web';

const applicationName = 'ClientPortal';
var appInsights: IApplicationInsights;

export const setupAppInsights = (getUserData: GetUserDataFn): ReactPlugin => {
  var userData: UserData;
  const reactPlugin = new ReactPlugin();
  const init = new ApplicationInsights({
    config: {
      appId: applicationName,
      connectionString: `InstrumentationKey=${__APP_INSIGHTS_KEY__}`,
      extensions: [reactPlugin],
      enableCorsCorrelation: true,
      correlationHeaderExcludedDomains: __APP_INSIGHTS_CORRELATION_EXCLUDE__,
      maxAjaxCallsPerView: -1,
      enableUnhandledPromiseRejectionTracking: true,
    },
  });
  appInsights = init.loadAppInsights();
  const telemetryInitializer = (envelope: ITelemetryItem) => {
    envelope.tags = envelope.tags ?? [];
    envelope.tags['ai.cloud.role'] = applicationName;
    if (userData) {
      envelope.tags['ai.session.id'] = userData.sessionId;
      envelope.tags['ai.user.id'] = userData.mpUserId ?? userData.userId;
    }
    if (envelope.baseType == 'PageviewData') {
      envelope.tags['ai.operation.name'] = window.location.pathname + window.location.search;
    }
  };
  appInsights.addTelemetryInitializer(telemetryInitializer);
  getUserData().then(data => (userData = data));
  window.addEventListener('single-spa:routing-event', _ => {
    // generate new operationId for every page view
    appInsights.context.telemetryTrace.traceID = generateW3CId();
    appInsights.trackPageView({});
  });
  return reactPlugin;
};
